class TocContainer extends HTMLElement {
    #open = false;
    #topLevel = 1;
    #bottomLevel = 6;
    #depth = 2;

    /** @type {HTMLDetailsElement} */
    #detailsElement;

    /** Called each time the element is added to the document. */
    connectedCallback() {
        this.#topLevel =
            parseInt(
                this.getAttribute('top-level')?.match(/^h([1-6])$/i)?.[1] ||
                this.getAttribute('top-level')?.match(/^([1-6])$/)?.[1]
            ) || 1;

        this.#bottomLevel =
            parseInt(
                this.getAttribute('bottom-level')?.match(/^h([1-6])$/i)?.[1] ||
                this.getAttribute('bottom-level')?.match(/^([1-6])$/)?.[1]
            ) || 6;

        this.#depth = parseInt(this.getAttribute('depth')) || 2;

        const openAttribute = this.getAttribute('open');
        this.#open = openAttribute !== null && openAttribute !== 'false';

        this.#detailsElement = document.createElement('details');
        this.#detailsElement.innerHTML = `<summary class="font-bold">Table of contents</summary>`;
        this.#detailsElement.append(this.#createTableOfContents());

        if (this.#open) {
            this.#detailsElement.open = true;
        }
        this.append(this.#detailsElement);
    }

    /**
     * Create table of contents HTML elements
     *
     * @returns {HTMLElement}
     */
    #createTableOfContents() {
        const contentSelector = this.hasAttribute('selector') ? this.getAttribute('selector') : undefined;
        const contentRoot = (contentSelector && document.querySelector(contentSelector)) || document;

        const headingLevels = [1, 2, 3, 4, 5, 6].filter((level) => {
            return level >= this.#topLevel && level <= this.#bottomLevel;
        });

        const headingTags = headingLevels.map((level) => `h${level}`);
        const headingSelector = headingTags.join(', ');

        const headings = contentRoot.querySelectorAll(headingSelector);

        const mappedHeadings = this.#getDataFromHeadings([...headings]);

        const contents = document.createElement('div');
        contents.classList.add('mb-none');

        contents.innerHTML = this.#generateHtmlFromHeadingData(mappedHeadings);
        return contents;
    }

    /**
     * Return or generate ID for heading
     *
     * @param {HTMLHeadingElement} heading
     * @param {number} index
     * @returns {string}
     */
    #getOrCreateId(heading, index) {
        return heading.id || `${heading.textContent.toLowerCase().replaceAll(' ', '_')}-${index}`;
    }

    /**
     * Map heading elements to array of heading attributes
     *
     * @param {Array.<HTMLHeadingElement>} headings
     * @returns {Array.<object>}
     */
    #getDataFromHeadings(headings) {
        let baseLevel = 6;

        // Create array of data from headings
        const mappedHeadings = headings.map((heading, index) => {
            const level = parseInt(heading.nodeName.replace('H', ''));
            baseLevel = Math.min(level, baseLevel);

            heading.id = this.#getOrCreateId(heading, index);

            return {
                content: heading.textContent,
                id: heading.id,
                level,
            };
        });

        // Filter out headings without text content
        const filteredHeadings = mappedHeadings
            .filter(({content}) => !!content)
            .filter(({level}) => level < baseLevel + this.#depth);

        // Adjust heading levels so first ol represents highest heading level present in list
        const releveledHeadings = filteredHeadings.map((heading) => {
            return {
                ...heading,
                level: heading.level - Math.max(baseLevel, this.#topLevel) + 1,
            };
        });

        return releveledHeadings;
    }

    /**
     * Generate HTML from heading objects
     *
     * @param {Array<object>} headings
     * @returns {string}
     */
    #generateHtmlFromHeadingData(headings) {
        return headings.reduce(function (output, heading, index) {
            const previousHeading = index > 0 ? headings[index - 1] : undefined;
            const previousLevel = previousHeading ? previousHeading.level : 0;
            const levelDifference = heading.level - previousLevel;

            // Open ordered list for each successive level
            if (levelDifference > 0) {
                for (let i = 0; i < levelDifference; i++) {
                    output += '<ol class="mb-none"><li class="list-disc">';
                }
            } else {
                // Close ordered list for each successive level
                if (levelDifference < 0) {
                    for (let i = 0; i > levelDifference; i--) {
                        output += '</li></ol>';
                    }
                }
                // Close previous and open new list item
                output += '</li><li class="list-disc">';
            }

            // Add link to list item
            output += `<a href="#${heading.id}">${heading.content}</a>`;

            // Close all open lists and items after final heading.
            if (index === headings.length - 1 && heading.level > 0) {
                for (let i = 0; i < heading.level; i++) {
                    output += '</li></ol>';
                }
            }

            return output;
        }, '');
    }
}

customElements.define('toc-container', TocContainer);
